import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

import Progress from "./Progress.js";

const FileUpload = ({ onComplete, currentFile }) => {
  const [file, setFile] = useState("");
  const [filename, setFilename] = useState("Choose File");
  const [uploadedFile, setUploadedFile] = useState({});
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const onChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    try {
      const res = await axios.post("/api/upload", formData, {
        headers: {
          "Content-Type": "mutipart/form-data",
        },
        onUploadProgress: (ProgressEvent) => {
          setUploadPercentage(
            parseInt(
              Math.round(ProgressEvent.loaded * 100) / ProgressEvent.total
            )
          );

          // Clear percentage
          setTimeout(() => setUploadPercentage(0), 5000);
        },
      });

      const { fileName, filePath } = res.data;
      setUploadedFile({ fileName, filePath });
      onComplete(filePath);
    } catch (err) {
      if (err.response.status === 500) {
        console.log("There was a problem with the server");
        console.log(err);
      } else {
        console.log(err.response.data);
      }
    }
  };

  return (
    <Fragment>
      <div className="profile file-upload">
        <div
          className={currentFile ? "avatar" : "avatar placeholder"}
          style={{ backgroundImage: `url(${currentFile})` }}
        ></div>
        <div className="inner">
          <Progress percentage={uploadPercentage} />
          <form onSubmit={onSubmit}>
            <div className="custom-file">
              <div className="form-group">
                <input
                  type="file"
                  id="customFile"
                  accepts=".jpg, .png"
                  onChange={onChange}
                />
              </div>
              <div className="form-group">
                <input type="submit" className="btn" value="Upload" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

FileUpload.propTypes = {
  onComplete: PropTypes.func.isRequired,
  currentFile: PropTypes.string,
};

export default FileUpload;
