import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import * as Constants from "../../utils/constants";

import Spinner from "../layout/Spinner";
import { getUserById } from "../../actions/user";
import { loadUser, resetPasswordRequest } from "../../actions/auth";

function User({
  match,
  loadUser,
  resetPasswordRequest,
  getUserById,
  user: { user, loading },
}) {
  useEffect(() => {
    loadUser();
    getUserById(match.params.id);
  }, [getUserById]);

  const resetPassword = (email) => {
    resetPasswordRequest(email);
  };

  return (
    <Fragment>
      {loading || !user ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="profile">
            <div className="edit">
              <Link to={`/edit-user/${match.params.id}`}>Edit</Link>
            </div>
            <div
              className={user.avatar ? "avatar" : "avatar placeholder"}
              style={{ backgroundImage: `url(${user.avatar})` }}
            ></div>
            <div className="inner">
              <p className="name">{user.name}</p>
              <p className="co">
                {user && Constants.adminLevels[user.access_level].name} of{" "}
                {user.company_id.name}
              </p>
            </div>
          </div>

          <div className="list profile-list">
            <div className="item">
              <div className="label">Email</div>
              <div className="data">
                <span>{user && user.email}</span>
                <div className="btn" onClick={() => resetPassword(user.email)}>
                  Change Password
                </div>
              </div>
            </div>
            <div className="item">
              <div className="label">Company</div>
              <div className="data">{user && user.company_id.name}</div>
            </div>
            {user && user.company_id._id !== "5eb1ee5875fd910dc0765105" ? (
              <div className="item communities">
                <div className="label">Communities</div>
                <div className="data">
                  <ul>
                    {user && user.communities.length > 0
                      ? user.communities.map((community) => (
                          <li key={community._id}>
                            <Link to={`/community/${community._id}`}>
                              {community.name}
                            </Link>
                          </li>
                        ))
                      : null}
                  </ul>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

User.propTypes = {
  resetPasswordRequest: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  getUserById,
  loadUser,
  resetPasswordRequest,
})(User);
