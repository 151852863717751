import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";

import UserPreview from "./UserPreview";

const SideBar = ({
  match,
  auth: { user, isAuthenticated, loading },
  logout,
}) => {
  const authLinks = (
    <ul>
      <li className={`${user && match.params.id === user._id ? "active" : ""}`}>
        <Link to={`/user/${user && user._id}`}>Profile</Link>
      </li>

      {user && user.access_level <= 1 && (
        <li
          className={`${
            match.path === "/companies" ||
            match.path === "/company/:id" ||
            match.path === "/edit-company/:id"
              ? "active"
              : ""
          }`}
        >
          <Link to="/companies">Companies</Link>
        </li>
      )}
      <li
        className={`${
          match.path === "/communities" ||
          match.path === "/community/:id" ||
          match.path === "/edit-community/:id"
            ? "active"
            : ""
        }`}
      >
        <Link to="/communities">Communities</Link>
      </li>
      {user && user.access_level <= 3 && (
        <li
          className={`${
            match.path === "/users" ||
            (match.path === "/user/:id" &&
              user &&
              match.params.id !== user._id) ||
            (match.path === "/edit-user/:id" &&
              user &&
              match.params.id !== user._id)
              ? "active"
              : ""
          }`}
        >
          <Link to="/users">Users</Link>
        </li>
      )}
    </ul>
  );

  const guestLinks = (
    <ul>
      <li>
        <Link to="/">Landing</Link>
      </li>
      <li>
        <Link to="/register">Register</Link>
      </li>
      <li>
        <Link to="/Login">Login</Link>
      </li>
    </ul>
  );

  return (
    <Fragment>
      {!loading && isAuthenticated && (
        <div className="sidebar">
          {!loading && <UserPreview />}
          <nav>
            {!loading && (
              <Fragment> {isAuthenticated ? authLinks : guestLinks}</Fragment>
            )}
          </nav>
        </div>
      )}
    </Fragment>
  );
};

SideBar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(SideBar);
