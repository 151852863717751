import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { getUserById, updateUser } from "../../actions/user";
import { getCompanies } from "../../actions/company";
import PropTypes from "prop-types";
import * as Constants from "../../utils/constants";

import FileUpload from "../layout/FileUpload";

const EditUser = ({
  match,
  setAlert,
  getUserById,
  getCompanies,
  updateUser,
  company: { companies },
  user: { user, loading },
  auth,
  history,
}) => {
  useEffect(() => {
    getUserById(match.params.id);
    getCompanies();
    setCompany();
  }, [getCompanies, getUserById, match]);

  useEffect(() => {
    setFormData({
      id: loading || !user ? null : user._id,
      name: loading || !user ? "" : user.name,
      company_id: loading || !user ? "" : user.company_id._id,
      access_level: loading || !user ? "" : user.access_level,
      avatar: loading || !user ? "" : user.avatar,
    });
  }, [user, loading]);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    company_id: "",
    access_level: "",
    avatar: "",
  });

  const { id, name, company_id, access_level, avatar } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    updateUser({ id, name, company_id, access_level, avatar }, history);
  };

  const onImageUpload = (url) => {
    setFormData({ ...formData, avatar: url });
  };

  // company options, admin shows select, other autosets to same co
  const setCompany = () => {
    if (auth.user && auth.user.access_level <= 1) {
      setFormData({ ...formData, company_id: auth.user.company_id });
    }
  };

  const companySelect = () => {
    if (auth.user && auth.user.access_level <= 1) {
      return (
        <select
          placeholder="Company"
          name="company_id"
          value={company_id}
          onChange={(e) => onChange(e)}
        >
          <option>Company</option>
          {companies.length > 0
            ? companies.map((company) => (
                <option value={company._id} key={company._id}>
                  {company.name}
                </option>
              ))
            : null}
        </select>
      );
    }
  };

  // admin options, admin shows select, other autosets to same co
  const adminSelect = () => {
    if (auth.user) {
      const availableLevels = Constants.adminLevels.filter(
        (admin) => admin.level >= auth.user.access_level
      );

      return (
        <select
          placeholder="Access Level"
          name="access_level"
          value={access_level}
          onChange={(e) => onChange(e)}
        >
          <option>Access Level</option>
          {availableLevels.length > 0
            ? availableLevels.map((admin) => (
                <option value={admin.level} key={admin.level}>
                  {admin.name}
                </option>
              ))
            : null}
        </select>
      );
    }
  };

  return (
    <Fragment>
      <h2>Edit User</h2>
      <FileUpload currentFile={avatar} onComplete={onImageUpload} />
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={name}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="form-group">{companySelect()}</div>
        <div className="form-group">{adminSelect()}</div>
        <button type="submit" className="btn">
          Update
        </button>
      </form>
    </Fragment>
  );
};

EditUser.propTypes = {
  setAlert: PropTypes.func.isRequired,
  getCompanies: PropTypes.func.isRequired,
  getUserById: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  company: state.company,
  user: state.user,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setAlert,
  getUserById,
  getCompanies,
  updateUser,
})(withRouter(EditUser));
