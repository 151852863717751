import axios from "axios";
import { setAlert } from "./alert";
import {
  ADD_FILTER,
  UPDATE_FILTER,
  REMOVE_FILTER,
  FILTER_ERROR,
  CLEAR_FILTERS,
  CLEAR_FILTERS_LEADS,
} from "./types";

// set fitlers
export const addFilter = (filterSettings) => async (dispatch) => {
  dispatch({
    type: ADD_FILTER,
    payload: filterSettings,
  });
};

// update fitler
export const updateFilter = (filterSettings) => async (dispatch) => {
  dispatch({
    type: UPDATE_FILTER,
    payload: filterSettings,
  });
};

// clear fitlers
export const clearFilters = () => async (dispatch) => {
  dispatch({
    type: CLEAR_FILTERS,
    payload: null,
  });
};

// clear fitlers
export const clearFiltersLeads = () => async (dispatch) => {
  dispatch({
    type: CLEAR_FILTERS_LEADS,
    payload: null,
  });
};

// remove single filter
export const removeTheFilter = (id) => async (dispatch) => {
  dispatch({
    type: REMOVE_FILTER,
    payload: id,
  });
};
