import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  GET_USERS,
  GET_USER,
} from "../actions/types";

const initalState = {
  loading: true,
  user: null,
  users: [],
};

export default function (state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USER:
      return {
        ...state,
        loading: false,
        user: payload,
      };
    case GET_USERS:
      return {
        ...state,
        loading: false,
        users: payload,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
