import React, { Fragment, useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
import PropTypes from "prop-types";

// Component
import TopBar from "../layout/TopBar";
import Alert from "../layout/Alert";

const Login = ({ login, user, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    login(email, password);
  };

  // Redirect if logged in
  if (isAuthenticated && user) {
    if (user.company_id._id == "5eb1ee5875fd910dc0765105") {
      return <Redirect to={`/user/${user._id}`} />;
    } else {
      return <Redirect to="/map" />;
    }
  }

  return (
    <div className="login-view">
      <header>
        <TopBar />
      </header>

      <div className="main">
        <div className="xs-cont">
          <Alert />
          <h2>Sign In</h2>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email Address"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
                required
              />
            </div>

            <button type="submit" className="btn">
              Sign In
            </button>

            <Link to="/reset-password-request" className="forgot-password">
              Forgot Password
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
