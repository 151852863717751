import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { uid } from "react-uid";

// Components
import DatePicker from "react-date-picker";
import Checkbox from "../layout/Checkbox";
import Dropdown from "../layout/Dropdown";
import Filter from "./Filter";

// Actions
import { addFilter, updateFilter } from "../../actions/filter";

// Actions
import { getLeads } from "../../actions/lead";

import {
  removeTheFilter,
  clearFilters,
  clearFiltersLeads,
} from "../../actions/filter";

// Constants
const colors = ["purple", "blue", "green", "blue", "red", "yellow"];
const compareOptions = [
  { _id: 0, key: "period", name: "Previous Period" },
  { _id: 1, key: "year", name: "Previous Year" },
];

function CompareController({
  community,
  leads,
  getLeads,
  clearFilters,
  clearFiltersLeads,
  removeTheFilter,
  updateFilter,
}) {
  // State
  const [colorId, setColorId] = useState(0);
  const [globalStartDate, setGlobalStartDate] = useState(new Date());
  const [globalEndDate, setGlobalEndDate] = useState(new Date());
  const [filters, setFilters] = useState([]);
  const [gradeOptions, setGradeOptions] = useState(undefined);
  const [sourceOptions, setSourceOptions] = useState(undefined);
  const [salesStatusOptions, setSalesStatusOptions] = useState(undefined);
  const [showSources, setShowSources] = useState(false);
  const [filterSources, setFilterSources] = useState("");

  const [sources, setSources] = useState([]);
  const [comparePeriod, setComparePeriod] = useState(undefined);

  const [showTraffic, setShowTraffic] = useState(true);
  const [showAgreements, setShowAgreements] = useState(true);
  const [globalShowAgreements, setGlobalShowAgreements] = useState("both");

  // setup on load
  useEffect(() => {
    // clear filters from store on start
    clearFilters();

    // setup globals
    //determine quarter
    var d = new Date();
    var m = Math.floor(d.getMonth() / 3);
    var q = m > 4 ? m - 4 : m;
    var start = 1 + (12 / 4) * (q - 1);
    var end = (12 / 4) * q;

    //set start and end date
    let startQuarter = new Date();
    startQuarter.setMonth(start - 1, 1);
    setGlobalStartDate(startQuarter);

    let endQuarter = new Date();
    endQuarter.setMonth(end, 0);
    setGlobalEndDate(endQuarter);
  }, []);

  // grab raw data
  useEffect(() => {
    if (community) {
      clearFiltersLeads();
      getLeads(community);

      if (filters.length === 0) {
        newFilter(globalStartDate, globalEndDate);
      }
    }
  }, [community]);

  // setup sort options from leads
  useEffect(() => {
    if (leads && leads.length > 0) {
      setupSortOptions();
    }
  }, [leads]);

  // agreement view logic
  useEffect(() => {
    if (showTraffic && showAgreements) {
      setGlobalShowAgreements("both");
    } else if (!showTraffic && showAgreements) {
      setGlobalShowAgreements("agreements");
    } else if (showTraffic && !showAgreements) {
      setGlobalShowAgreements("traffic");
    } else {
      setGlobalShowAgreements("none");
    }
  }, [showTraffic, showAgreements]);

  // force main filters from compare UI
  useEffect(() => {
    setFromUI();
  }, [globalStartDate, globalEndDate, comparePeriod]);

  const setFromUI = () => {
    let _filters = [...filters];

    // set first dates
    if (_filters.length > 0) {
      _filters[0].initStartDate = globalStartDate;
      _filters[0].initEndDate = globalEndDate;
    }

    if (comparePeriod) {
      let _initStartDate = new Date(globalStartDate);
      let _initEndDate = new Date(globalEndDate);

      // setup compare period
      switch (comparePeriod) {
        case "period":
          _initStartDate.setMonth(_initStartDate.getMonth() - 3);
          _initEndDate.setMonth(_initEndDate.getMonth() - 3);

          break;
        case "month":
          _initStartDate.setMonth(_initStartDate.getMonth() - 1);
          _initEndDate.setMonth(_initEndDate.getMonth() - 1);

          break;
        case "year":
          _initStartDate.setMonth(_initStartDate.getMonth() - 12);
          _initEndDate.setMonth(_initEndDate.getMonth() - 12);

          break;
      }

      if (_filters.length == 2) {
        // set second
        _filters[1].initStartDate = _initStartDate;
        _filters[1].initEndDate = _initEndDate;
      } else {
        // create second
        // pick color
        if (colorId < colors.length - 1) {
          let newClrId = colorId + 1;
          setColorId(newClrId);
        } else {
          setColorId(0);
        }

        let filter = {
          id: uid([], 1),
          color: colors[colorId],
          initStartDate: _initStartDate,
          initEndDate: _initEndDate,
        };
        _filters.push(filter);
      }
    }

    setFilters(_filters);
  };

  // date range
  const onChangeStart = (date) => setGlobalStartDate(date);
  const onChangeEnd = (date) => setGlobalEndDate(date);

  // sources
  const displaySources = () => {
    if (sourceOptions instanceof Array) {
      let _sourceOptions = sourceOptions;

      if (filterSources) {
        _sourceOptions = _sourceOptions.filter((object) =>
          object.toLowerCase().includes(filterSources.toLowerCase())
        );
      }

      return _sourceOptions.map(function (object, i) {
        return (
          <Checkbox
            obj={object}
            key={uid(object, i)}
            id={uid(object, i)}
            prefix="source"
            onCheckbox={sourceChange}
            defaultValue={true}
          />
        );
      });
    }
  };

  const filterTheSources = (e) => {
    setFilterSources(e.target.value);
  };

  const sourceChange = (event) => {
    if (event.currentTarget.checked) {
      var _sources = [...sources];
      _sources.push(event.currentTarget.value);

      setSources(_sources); 
    } else {
      var _sources = [...sources].filter(
        (item) => item !== event.currentTarget.value
      );
      setSources(_sources);
    }
  };

  // New filter
  const newFilter = (initStartDate, initEndDate) => {
    //determine quarter
    var d = new Date();
    var m = Math.floor(d.getMonth() / 3);
    var q = m > 4 ? m - 4 : m;
    var start = 1 + (12 / 4) * (q - 1);
    var end = (12 / 4) * q;

    //set start and end date
    let startQuarter = new Date();
    startQuarter.setMonth(start - 1, 1);

    let endQuarter = new Date();
    endQuarter.setMonth(end, 0);

    // pick color
    if (colorId < colors.length - 1) {
      let newClrId = colorId + 1;
      setColorId(newClrId);
    } else {
      setColorId(0);
    }

    let filter = {
      id: uid([], 1),
      color: colors[colorId],
      initStartDate: initStartDate ? initStartDate : startQuarter,
      initEndDate: initEndDate ? initEndDate : endQuarter,
    };
    setFilters(filters.concat(filter));
  };

  // Remove filter
  const removeFilter = (id) => {
    let _filters = filters.filter((item, itemIndex) => item.id !== id);
    setFilters(_filters);
    removeTheFilter(id);

    // pick color
    if (colorId > 0) {
      let newClrId = colorId - 1;
      setColorId(newClrId);
    } else {
      setColorId(0);
    }
  };

  // set our comparison
  const setCompare = (period) => console.log(period);

  // Pull sorting options
  const setupSortOptions = () => {
    let _StartDate = new Date();
    let _EndDate = null;
    let _gradeOptions = [];
    let _sourceOptions = [];
    let _salesStatusOptions = [];

    if (leads) {
      _EndDate = new Date(leads[0].date);

      leads.map((point) => {
        if (_sourceOptions.indexOf(point.source.trim()) === -1) {
          _sourceOptions.push(point.source.trim());
        }

        if (_gradeOptions.indexOf(point.grade) === -1) {
          _gradeOptions.push(point.grade);
        }

        if (_salesStatusOptions.indexOf(point.salesStatus) === -1) {
          _salesStatusOptions.push(point.salesStatus);
        }
      });

      setGradeOptions(_gradeOptions);
      setSourceOptions(_sourceOptions);
      setSalesStatusOptions(_salesStatusOptions);
      setSources(_sourceOptions);
    }
  };

  return (
    <div className={community ? "full compare-controller active" : "full compare-controller"}>
      <div className="dates">
        <p className="title">Date Range:</p>
        <DatePicker onChange={onChangeStart} value={globalStartDate} />
        <span>to</span>
        <DatePicker onChange={onChangeEnd} value={globalEndDate} />
      </div>

      <div className="compare">
        <p className="title">Compare to:</p>
        <Dropdown
          title="Select Comparison"
          options={compareOptions}
          onChange={(obj) => setComparePeriod(compareOptions[obj].key)}
        />
      </div>

      <div className={showSources ? "sources active" : "sources"}>
        <div
          className="title"
          onClick={() => {
            setShowSources(!showSources);
            setFilterSources("");
          }}
        >
          Filter by Source
        </div>
        <div className="list">
          <div className="filter">
            <input
              type="text"
              placeholder="Filter"
              name="source-filter"
              value={filterSources}
              onChange={(e) => filterTheSources(e)}
            />
            <div
              className="clear"
              onClick={() => {
                setFilterSources("");
              }}
            >
              &times;
            </div>
          </div>
          {displaySources()}
        </div>
      </div>

      <div className="show-hide">
        <p className="title">Show/Hide:</p>
        <div className="options">
          <div
            className={showTraffic ? "option active" : "option inactive"}
            onClick={() => {
              setShowTraffic(!showTraffic);
            }}
          >
            <img src={`images/icons/svg/pin-purple.svg`} className="icon" />
            <span>Traffic</span>
          </div>

          <div
            className={showAgreements ? "option active" : "option inactive"}
            onClick={() => {
              setShowAgreements(!showAgreements);
            }}
          >
            <img src={`images/icons/svg/agreement-purple.svg`} className="icon" />
            <span>Agreements</span>
          </div>
        </div>
      </div>

      

      <div
        className={
          filters.length > 1 ? "filters legend" : "filters legend primary"
        }
      >
        {filters.length > 0 ? (
          filters.map((filter) => (
            <Filter
              key={filter.id}
              id={filter.id}
              color={filter.color}
              gradeOptions={gradeOptions}
              sourceOptions={sources}
              salesStatusOptions={salesStatusOptions}
              globalShowAgreements={globalShowAgreements}
              removeFilter={removeFilter}
              addNewFilter={newFilter}
              initStartDate={filter.initStartDate}
              initEndDate={filter.initEndDate}
              primary={filters.length}
            />
          ))
        ) : (
          <p>Please select a community.</p>
        )}
      </div>
    </div>
  );
}

CompareController.propTypes = {
  getLeads: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  clearFiltersLeads: PropTypes.func.isRequired,
  removeTheFilter: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  community: PropTypes.string,
};

const mapStateToProps = (state) => ({
  filters: state.filter.filters,
  leads: state.lead.leads,
});

export default connect(mapStateToProps, {
  updateFilter,
  getLeads,
  clearFilters,
  clearFiltersLeads,
  removeTheFilter,
})(CompareController);
