import {
  GET_COMMUNITY,
  GET_COMMUNITIES,
  COMMUNITY_ERROR,
  CLEAR_COMMUNITY,
} from "../actions/types";

const initalState = {
  community: null,
  communities: [],
  loading: true,
  error: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COMMUNITY:
      return {
        ...state,
        community: payload,
        loading: false,
      };
    case GET_COMMUNITIES:
      return {
        ...state,
        communities: payload,
        loading: false,
      };
    case COMMUNITY_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_COMMUNITY:
      return {
        ...state,
        community: null,
        loading: false,
      };
    default:
      return state;
  }
}
