import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";

const TopBar = ({
  match,
  auth: { isAuthenticated, loading, user },
  logout,
}) => {
  const authLinks = (
    <ul>
      <li className="map-link">
        {match && match.path !== "/map" ? (
          <Link to="/map">Map</Link>
        ) : (
          <Link to={user && `/user/${user._id}`}>Admin</Link>
        )}
      </li>
      <li>
        <a onClick={logout} href="#!">
          Sign Out
        </a>
      </li>
    </ul>
  );

  const guestLinks = (
    <ul>
      <li>
        <Link to="/Login">Sign In</Link>
      </li>
    </ul>
  );

  return (
    <div className="topbar">
      {!loading && (
        <Fragment>
          <Link to="/map" className="logo">
            <img src="/images/logos/pinnd-logo.png" />
          </Link>
          {isAuthenticated ? authLinks : guestLinks}
        </Fragment>
      )}
    </div>
  );
};

TopBar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(TopBar);
