import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getCurrentCompany } from "../../actions/company";

const Dashboard = ({
  getCurrentCompany,
  auth: { user },
  company: { company, loading },
}) => {
  /*useEffect(() => {
    if (user && user.company_id) {
      getCurrentCompany();
    }
  }, [user]);

  return loading && company === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1>{company ? company.name : ""} Dashboard</h1>
      <p>Welcome {user.name}</p>
    </Fragment>
  );*/
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
};

Dashboard.propTypes = {
  getCurrentCompany: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
});

export default connect(mapStateToProps, { getCurrentCompany })(Dashboard);
