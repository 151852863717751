import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const CommunityItem = ({ community: { _id, name, company_id } }) => {
  return (
    <div className="item">
      <Link to={`/community/${_id}`}>{name}</Link>
      <div className="links">
        <Link to={`/edit-community/${_id}`}>Edit</Link>
      </div>
    </div>
  );
};

CommunityItem.propTypes = {
  community: PropTypes.object.isRequired,
};

export default CommunityItem;
