import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_COMPANY,
  GET_COMPANIES,
  CLEAR_COMPANY,
  COMPANY_ERROR,
} from "./types";

// Get current users company
export const getCurrentCompany = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/company/current");

    dispatch({
      type: GET_COMPANY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COMPANY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Get company by ID
export const getCompanyById = (id) => async (dispatch) => {
  dispatch({ type: CLEAR_COMPANY });

  try {
    const res = await axios.get(`/api/company/single/${id}`);

    dispatch({
      type: GET_COMPANY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COMPANY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// get all companies
export const getCompanies = () => async (dispatch) => {
  dispatch({ type: CLEAR_COMPANY });

  try {
    const res = await axios.get("/api/company");

    dispatch({
      type: GET_COMPANIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COMPANY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Ceate or update company
export const createCompany = (formData, history, edit = false) => async (
  dispatch
) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post("/api/company", formData, config);

    dispatch({
      type: GET_COMPANY,
      payload: res.data,
    });

    dispatch(setAlert(edit ? "Company updated" : "Company added"));

    history.push(`/company/${res.data._id}`);
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: COMPANY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Delete company by ID
export const deleteCompany = (id, history) => async (dispatch) => {
  dispatch({ type: CLEAR_COMPANY });

  try {
    const res = await axios.delete(`/api/company/${id}`);

    dispatch(setAlert("Company deleted"));

    history.push("/companies");
  } catch (err) {
    dispatch({
      type: COMPANY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
