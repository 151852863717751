import React, { Fragment, useState, useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { resetPassword } from "../../actions/auth";
import PropTypes from "prop-types";

// Component
import TopBar from "../layout/TopBar";
import Alert from "../layout/Alert";

const ResetPassword = ({
  match,
  setAlert,
  resetPassword,
  isAuthenticated,
  history,
}) => {
  useEffect(() => {
    //console.log(match.params.token);
    //const token = match.params.token;
    console.log("loaded");
  }, []);

  const [formData, setFormData] = useState({
    password: "",
    password2: "",
  });

  const { password, password2 } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== password2 || password === "") {
      setAlert("Passwords do not match", "danger", 3000);
    } else {
      resetPassword(password, match.params.token, history);
    }
  };

  // Redirect if logged in or no token
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  if (!match.params.token) {
    return <Redirect to="/" />;
  }

  return (
    <div className="login-view">
      <header>
        <TopBar />
      </header>

      <div className="main">
        <div className="xs-cont">
          <Alert />
          <h2>Reset Password</h2>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <input
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                placeholder="Confirm Password"
                name="password2"
                value={password2}
                onChange={(e) => onChange(e)}
              />
            </div>

            <button type="submit" className="btn">
              Reset
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  setAlert: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, resetPassword })(
  withRouter(ResetPassword)
);
