import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// Component
import TopBar from "../layout/TopBar";
import SideBar from "../layout/SideBar";
import Alert from "../layout/Alert";

const PrivateAdminRoute = ({
  component: Component,
  auth: { isAuthenticated, loading },
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated && !loading ? (
        <Redirect to="/login" />
      ) : (
        <div className="admin-view">
          <header>
            <TopBar {...props} />
          </header>
          <SideBar {...props} />
          <div className="main">
            <div className="cont">
              <Alert />
              <Component {...props} />
            </div>
          </div>
        </div>
      )
    }
  />
);

PrivateAdminRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateAdminRoute);
