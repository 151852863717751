import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

var mapStyles = [
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

function Map({ initZoom, initLat, initLng, filters, filterRefresh }) {
  const googleMapRef = React.createRef();
  const [map, setMap] = useState();
  const [points, setPoints] = useState();
  const [apiLoaded, setApiLoaded] = useState(false);

  // setup map
  useEffect(() => {
    const onLoad = () => {
      setApiLoaded(true);
    };
    if (!window.google) {
      const script = document.createElement(`script`);
      script.src =
        `https://maps.googleapis.com/maps/api/js?key=` +
        `AIzaSyCoVhmIj4l2f6nLfnSrbKxWYNofNuyV4Eo`;
      document.head.append(script);
      script.addEventListener(`load`, onLoad);
      return () => script.removeEventListener(`load`, onLoad);
    } else onLoad();
  }, []);

  useEffect(() => {
    if (apiLoaded == true) {
      createGoogleMap();
    }
  }, [apiLoaded]);

  // load points
  useEffect(() => {
    clearMap();
    addMarkers();
  }, [filters, filterRefresh]);

  // recenter map when points updated
  useEffect(() => {
    fitMap();
  }, [points]);

  const createGoogleMap = () => {
    var map = new window.google.maps.Map(googleMapRef.current, {
      zoom: initZoom,
      center: {
        lat: initLat,
        lng: initLng,
      },
      disableDefaultUI: true,
      zoomControl: true,
      styles: mapStyles,
    });

    setMap(map);
  };

  const clearMap = () => {
    if (points) {
      for (var i = 0; i < points.length; i++) {
        points[i].setMap(null);
      }
    }
  };

  const addMarkers = () => {
    if (window.google && filters) {
      var markers = [];
      for (var i = 0; i < filters.length; i++) {
        if (filters[i].leads) {
          for (var j = 0; j < filters[i].leads.length; j++) {
            var point = filters[i].leads[j];

            //check for undefines from the zipcode resource
            if (point.latitude && point.longitude) {
              var offsetValue = -0.03;
              var newLat = point.latitude + offsetValue * Math.random(-1, 1); //x
              var newLng = point.longitude + offsetValue * Math.random(-1, 1); //Y
              var iconImage = "";

              if (point.salesStatus !== "") {
                iconImage = "images/icons/svg/agreement-" + filters[i].color + ".svg";
              } else {
                iconImage = "images/icons/svg/pin-" + filters[i].color + ".svg";                
              }

              var icon = {
                url: iconImage,
                scaledSize: new window.google.maps.Size(22, 33),
              };

              var marker = new window.google.maps.Marker({
                position: {
                  lat: newLat,
                  lng: newLng,
                },
                icon: icon,
                map: map,
              });

              markers.push(marker);
            }
          }
        }
      }
      setPoints(markers);
    }
  };

  const fitMap = () => {
    //center and show all points
    if (window.google && points) {
      if (points.length > 0) {
        var bounds = new window.google.maps.LatLngBounds();
        for (var i = 0; i < points.length; i++) {
          bounds.extend(points[i].getPosition());
        }
        map.fitBounds(bounds);
      } else {
        if (map) {
          map.setZoom(initZoom);
          map.setCenter({ lat: initLat, lng: initLng });
        }
      }
    }
  };

  return (
    <div className="map-cont">
      <div
        id="google-map"
        ref={googleMapRef}
      />
    </div>
  );
}

Map.propTypes = {
  initZoom: PropTypes.number.isRequired,
  initLat: PropTypes.number.isRequired,
  initLng: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  filters: state.filter.filters,
  filterRefresh: state.filter.filterRefresh,
});

export default connect(mapStateToProps, {})(Map);
