import axios from "axios";
import { setAlert } from "./alert";
import { GET_LEADS, LEAD_ERROR, CLEAR_LEADS } from "./types";

// get all leads
export const getLeads = (community_id) => async (dispatch) => {
  dispatch({ type: CLEAR_LEADS });

  try {
    const res = await axios.get(`/api/lead/community/${community_id}`);

    dispatch({
      type: GET_LEADS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: LEAD_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
