import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const CompanyItem = ({ company: { _id, name } }) => {
  return (
    <div className="item">
      <Link to={`/company/${_id}`}>{name}</Link>
      <div className="links">
        <Link to={`/edit-company/${_id}`}>Edit</Link>
      </div>
    </div>
  );
};

CompanyItem.propTypes = {
  company: PropTypes.object.isRequired,
};

export default CompanyItem;
