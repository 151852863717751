import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";

// Style
import "./App.scss";

// Component
import Login from "./components/auth/Login";
import ResetPasswordRequest from "./components/auth/ResetPasswordRequest";
import ResetPassword from "./components/auth/ResetPassword";
import Dashboard from "./components/dashboard/Dashboard";
//import Landing from "./components/layout/Landing";

import MapView from "./components/mapView/MapView";

import User from "./components/user/User";
import EditUser from "./components/user/EditUser";
import RegisterUser from "./components/user/RegisterUser";
import Users from "./components/user/Users";

import PrivateRoute from "./components/routing/PrivateRoute";
import PrivateAdminRoute from "./components/routing/PrivateAdminRoute";

// Companies
import Company from "./components/company/Company";
import CreateCompany from "./components/company/CreateCompany";
import EditCompany from "./components/company/EditCompany";
import Companies from "./components/company/Companies";

// Communities
import Community from "./components/community/Community";
import CreateCommunity from "./components/community/CreateCommunity";
import EditCommunity from "./components/community/EditCommunity";
import Communities from "./components/community/Communities";
import AssignCommunity from "./components/community/AssignCommunity";

// Utils
import setAuthToken from "./utils/setAuthToken";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          <Route exact path="/" component={Login} />
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route
              exact
              path="/reset-password/:token?"
              component={ResetPassword}
            />
            <Route
              exact
              path="/reset-password-request/"
              component={ResetPasswordRequest}
            />
            <PrivateAdminRoute exact path="/dashboard" component={Dashboard} />

            <PrivateAdminRoute exact path="/user/:id" component={User} />
            <PrivateAdminRoute
              exact
              path="/register-user"
              component={RegisterUser}
            />
            <PrivateAdminRoute
              exact
              path="/edit-user/:id"
              component={EditUser}
            />
            <PrivateAdminRoute exact path="/users" component={Users} />

            <PrivateAdminRoute exact path="/company/:id" component={Company} />
            <PrivateAdminRoute
              exact
              path="/edit-company/:id"
              component={EditCompany}
            />
            <PrivateAdminRoute exact path="/companies" component={Companies} />
            <PrivateAdminRoute
              exact
              path="/create-company"
              component={CreateCompany}
            />

            <PrivateAdminRoute
              exact
              path="/community/:id"
              component={Community}
            />
            <PrivateAdminRoute
              exact
              path="/edit-community/:id"
              component={EditCommunity}
            />
            <PrivateAdminRoute
              exact
              path="/communities"
              component={Communities}
            />
            <PrivateAdminRoute
              exact
              path="/create-community"
              component={CreateCommunity}
            />
            <PrivateAdminRoute
              exact
              path="/assign-community"
              component={AssignCommunity}
            />

            <PrivateRoute exact path="/map" component={MapView} />
          </Switch>
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
