export const adminLevels = [
  {
    name: "Super Admin",
    level: 0,
  },
  {
    name: "Rhoads Admin",
    level: 1,
  },
  {
    name: "Company Admin",
    level: 2,
  },
  {
    name: "Company Manager",
    level: 3,
  },
  {
    name: "Company User",
    level: 4,
  },
];
