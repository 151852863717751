import { GET_LEADS, LEAD_ERROR, CLEAR_LEADS } from "../actions/types";

const initalState = {
  leads: null,
  loading: true,
  error: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LEADS:
      return {
        ...state,
        leads: payload,
        loading: false,
      };
    case LEAD_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_LEADS:
      return {
        ...state,
        leads: null,
        loading: false,
      };
    default:
      return state;
  }
}
