import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Spinner from "../layout/Spinner";
import { getCompanies } from "../../actions/company";
import CompanyItem from "./CompanyItem";

function Companies({ getCompanies, company: { companies, loading }, user }) {
  useEffect(() => {
    getCompanies();
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h2>All Companies</h2>
          <div className="companies list">
            {companies.length > 0 ? (
              companies.map((company) => (
                <CompanyItem key={company._id} company={company} />
              ))
            ) : (
              <p>No companies found...</p>
            )}
          </div>
          {user && user.access_level <= 1 && (
            <div className="btn-cont">
              <Link to="/create-company">Add Company</Link>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

Companies.propTypes = {
  getCompanies: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  company: state.company,
  user: state.auth.user,
});

export default connect(mapStateToProps, { getCompanies })(Companies);
