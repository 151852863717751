import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const UserPreview = ({ auth: { user } }) => {
  return (
    <Fragment>
      {user && (
        <div className="profile-preview">
          <div
            className={user.avatar ? "avatar" : "avatar placeholder"}
            style={{ backgroundImage: `url(${user.avatar})` }}
          ></div>
          <div className="inner">
            <p className="name">
              <strong>{user.name}</strong>
            </p>
            <p className="co">
              <em>{user.company_id.name}</em>
            </p>
          </div>
        </div>
      )}
    </Fragment>
  );
};

UserPreview.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(UserPreview);
