import React, { Fragment, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { resetPasswordRequest } from "../../actions/auth";
import PropTypes from "prop-types";

// Component
import TopBar from "../layout/TopBar";
import Alert from "../layout/Alert";

const ResetPasswordRequest = ({ resetPasswordRequest, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    resetPasswordRequest(email);
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <div className="login-view">
      <header>
        <TopBar />
      </header>

      <div className="main">
        <div className="xs-cont">
          <Alert />
          <h2>Reset Password</h2>
          <form onSubmit={(e) => onSubmit(e)}>
            <div className="form-group">
              <input
                type="email"
                placeholder="Email Address"
                name="email"
                value={email}
                onChange={(e) => onChange(e)}
                required
              />
            </div>

            <button type="submit" className="btn">
              Reset
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

ResetPasswordRequest.propTypes = {
  resetPasswordRequest: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { resetPasswordRequest })(
  ResetPasswordRequest
);
