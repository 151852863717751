import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Spinner from "../layout/Spinner";
import { getCompanyById } from "../../actions/company";

function Company({ match, getCompanyById, company: { company, loading } }) {
  useEffect(() => {
    getCompanyById(match.params.id);
  }, [getCompanyById]);

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="profile">
            <div className="edit">
              <Link to={`/edit-company/${match.params.id}`}>Edit</Link>
            </div>
            <div
              className={
                company && company.logo ? "avatar" : "avatar placeholder"
              }
              style={{ backgroundImage: `url(${company && company.logo})` }}
            ></div>
            <div className="inner">
              <p className="name">{company && company.name}</p>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

Company.propTypes = {
  getCompanyById: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  company: state.company,
});

export default connect(mapStateToProps, { getCompanyById })(Company);
