import React, { Fragment, useState, useEffect } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/user";
import { getCompanies } from "../../actions/company";
import PropTypes from "prop-types";
import * as Constants from "../../utils/constants";

const RegisterUser = ({
  setAlert,
  register,
  getCompanies,
  company: { companies, loading },
  auth: { user, isAuthenticated },
  history,
}) => {
  useEffect(() => {
    getCompanies();
    setCompany();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company_id: "",
    access_level: "",
    password: "",
    password2: "",
  });

  const {
    name,
    email,
    company_id,
    access_level,
    password,
    password2,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (password !== password2) {
      setAlert("Passwords do not match", "danger", 3000);
    } else {
      register({ name, email, company_id, access_level, password }, history);
    }
  };

  // company options, admin shows select, other autosets to same co
  const setCompany = () => {
    if (user && user.access_level > 1) {
      setFormData({ ...formData, company_id: user.company_id._id });
    }
  };

  const companySelect = () => {
    if (user.access_level <= 1) {
      return (
        <select
          placeholder="Company"
          name="company_id"
          value={company_id}
          onChange={(e) => onChange(e)}
        >
          <option>Company</option>
          {companies.length > 0
            ? companies.map((company) => (
                <option value={company._id} key={company._id}>
                  {company.name}
                </option>
              ))
            : null}
        </select>
      );
    }
  };

  // admin options, admin shows select, other autosets to same co
  const adminSelect = () => {
    const availableLevels = Constants.adminLevels.filter(
      (admin) => admin.level >= user.access_level
    );

    return (
      <select
        placeholder="Access Level"
        name="access_level"
        value={access_level}
        onChange={(e) => onChange(e)}
      >
        <option>Access Level</option>
        {availableLevels.length > 0
          ? availableLevels.map((admin) => (
              <option value={admin.level} key={admin.level}>
                {admin.name}
              </option>
            ))
          : null}
      </select>
    );
  };

  // Redirect if logged in
  if (!isAuthenticated || !user) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Fragment>
      <h2>Create User</h2>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Name"
            name="name"
            value={name}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            placeholder="Email Address"
            name="email"
            value={email}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="form-group">{companySelect()}</div>
        <div className="form-group">{adminSelect()}</div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Confirm Password"
            name="password2"
            value={password2}
            onChange={(e) => onChange(e)}
          />
        </div>

        <button type="submit" className="btn">
          Add
        </button>
      </form>
    </Fragment>
  );
};

RegisterUser.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  getCompanies: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
});

export default connect(mapStateToProps, { setAlert, register, getCompanies })(
  withRouter(RegisterUser)
);
