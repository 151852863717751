import React, { Fragment, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createCompany } from "../../actions/company";

import FileUpload from "../layout/FileUpload";

const CreateCompany = ({ createCompany, history }) => {
  const [formData, setFormData] = useState({
    name: "",
    logo: "",
  });

  const { name, logo } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    createCompany(formData, history);
  };

  const onImageUpload = (url) => {
    setFormData({ ...formData, logo: url });
  };

  return (
    <Fragment>
      <h2>Add Company</h2>
      <FileUpload onComplete={onImageUpload} />
      <img src={logo} className="avatar" />
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Company Name"
            name="name"
            value={name}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <button className="btn" type="submit">
          Add
        </button>
      </form>
    </Fragment>
  );
};

CreateCompany.propTypes = { createCompany: PropTypes.func.isRequired };

export default connect(null, { createCompany })(withRouter(CreateCompany));
