import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createCommunity } from "../../actions/community";
import { getCompanies } from "../../actions/company";

const CreateCommunity = ({
  getCompanies,
  createCommunity,
  company: { companies, loading },
  auth: { user, isAuthenticated },
  history,
}) => {
  useEffect(() => {
    getCompanies();
    setCompany();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    latitude: "",
    longitude: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    company_id: "",
    status: 0,
  });

  const {
    name,
    latitude,
    longitude,
    address,
    city,
    state,
    zipcode,
    company_id,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    createCommunity(formData, history);
  };

  // company options, admin shows select, other autosets to same co
  const setCompany = () => {
    if (user && user.access_level !== 0) {
      setFormData({ ...formData, company_id: user.company_id._id });
    }
  };

  const companySelect = () => {
    if (user.access_level === 0) {
      return (
        <select
          placeholder="Company"
          name="company_id"
          value={company_id}
          onChange={(e) => onChange(e)}
        >
          <option>Company</option>
          {companies.length > 0
            ? companies.map((company) => (
                <option value={company._id} key={company._id}>
                  {company.name}
                </option>
              ))
            : null}
        </select>
      );
    }
  };

  return (
    <Fragment>
      <h2>Add Community</h2>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Community Name"
            name="name"
            value={name}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Latitude"
            name="latitude"
            value={latitude}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Longitude"
            name="longitude"
            value={longitude}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Address"
            name="address"
            value={address}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="City"
            name="city"
            value={city}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="State"
            name="state"
            value={state}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="form-group">
          <input
            type="number"
            placeholder="Zipcode"
            name="zipcode"
            value={zipcode}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="form-group">{companySelect()}</div>
        <button className="btn" type="submit">
          Add
        </button>
      </form>
    </Fragment>
  );
};

CreateCommunity.propTypes = {
  auth: PropTypes.object.isRequired,
  getCompanies: PropTypes.func.isRequired,
  createCommunity: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  company: state.company,
});

export default connect(mapStateToProps, { getCompanies, createCommunity })(
  withRouter(CreateCommunity)
);
