import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  createCommunity,
  deleteCommunity,
  getCommunityById,
} from "../../actions/community";
import { getUsers } from "../../actions/user";
import { getCompanies, getCompanyById } from "../../actions/company";

// Components
import TabSection from "../layout/TabSection";
import Checkbox from "../layout/Checkbox";

const EditCommunity = ({
  match,
  createCommunity,
  deleteCommunity,
  getCommunityById,
  getCompanies,
  getUsers,
  community: { community, loading },
  company: { companies },
  users,
  auth,
  history,
}) => {
  useEffect(() => {
    getCommunityById(match.params.id);
    getCompanies();
    setCompany();
    getUsers();
  }, []);

  useEffect(() => {
    let filtered = [];
    if (users.length > 0 && community) {
      users.forEach((user) => {
        let obj = {
          user: null,
          access: null,
        };
        if (user.company_id._id === community.company_id._id) {
          obj.user = user;
          user.communities.filter((com) => com._id === community._id).length > 0
            ? (obj.access = true)
            : (obj.access = false);

          if (user.access_level === 2 || user.access_level === 3) {
            //admins and managers preset
            obj.access = true;
          }

          filtered.push(obj);
        }
      });
    }

    setFormData({
      id: loading || !community ? null : community._id,
      name: loading || !community ? "" : community.name,
      latitude: !community ? "" : community.latitude,
      longitude: !community ? "" : community.longitude,
      address: !community ? "" : community.address,
      city: !community ? "" : community.city,
      state: !community ? "" : community.state,
      zipcode: !community ? "" : community.zipcode,
      company_id: loading || !community ? "" : community.company_id._id,
      status: !community ? "" : community.status,
      assignedUsers: !users ? [] : filtered,
    });
  }, [community, users]);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    latitude: "",
    longitude: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    company_id: "",
    assignedUsers: [],
    status: 0,
  });

  const {
    id,
    name,
    latitude,
    longitude,
    address,
    city,
    state,
    zipcode,
    company_id,
    assignedUsers,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const submit = (e) => {
    e.preventDefault();
    createCommunity(formData, history, true);
  };

  const deleteTheCommunity = () => {
    deleteCommunity(id, history);
  };

  // company options, admin shows select, other autosets to same co
  const setCompany = () => {
    if (auth.user && auth.user.access_level !== 0) {
      setFormData({ ...formData, company_id: auth.user.company_id });
    }
  };

  const companySelect = () => {
    if (auth.user && auth.user.access_level === 0) {
      return (
        <select
          placeholder="Company"
          name="company_id"
          value={company_id}
          onChange={(e) => onChange(e)}
        >
          <option>Company</option>
          {companies.length > 0
            ? companies.map((company) => (
                <option value={company._id} key={company._id}>
                  {company.name}
                </option>
              ))
            : null}
        </select>
      );
    }
  };

  return (
    <div className="edit-community">
      <h2>Edit Community</h2>
      <TabSection
        content={[
          {
            nav: "Community Information",
            content: (
              <Fragment>
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Community Name"
                      name="name"
                      value={name}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Latitude"
                      name="latitude"
                      value={latitude}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Longitude"
                      name="longitude"
                      value={longitude}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={address}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="City"
                      name="city"
                      value={city}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="State"
                      name="state"
                      value={state}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="number"
                      placeholder="Zipcode"
                      name="zipcode"
                      value={zipcode}
                      onChange={(e) => onChange(e)}
                    />
                  </div>
                  <div className="form-group">{companySelect()}</div>
                </form>
              </Fragment>
            ),
          },
          {
            nav: "Users",
            content: (
              <Fragment>
                <div className="list assign">
                  {assignedUsers.length > 0 ? (
                    assignedUsers.map((user, index) => (
                      <div className="item" key={index}>
                        <span>{user.user.name}</span>
                        <div
                          className={
                            user.access ? "checkmark checked" : "checkmark"
                          }
                          disabled={
                            user.user.access_level === 2 ||
                            user.user.access_level === 3
                          }
                        >
                          <input
                            type="checkbox"
                            value={user._id}
                            onChange={() => {
                              let _users = assignedUsers;
                              _users[index].access = !_users[index].access;
                              setFormData({
                                ...formData,
                                assignedUsers: _users,
                              });
                            }}
                            checked={user.access}
                            disabled={
                              user.user.access_level === 2 ||
                              user.user.access_level === 3
                            }
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No users found...</p>
                  )}
                </div>
              </Fragment>
            ),
          },
        ]}
      />
      <button type="submit" className="btn" onClick={submit}>
        Update
      </button>
      <button
        onClick={() => {
          deleteTheCommunity();
        }}
        className="btn red"
      >
        Delete
      </button>
    </div>
  );
};

EditCommunity.propTypes = {
  createCommunity: PropTypes.func.isRequired,
  deleteCommunity: PropTypes.func.isRequired,
  getCommunityById: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  community: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  community: state.community,
  company: state.company,
  users: state.user.users,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  createCommunity,
  deleteCommunity,
  getCommunityById,
  getCompanies,
  getUsers,
})(withRouter(EditCommunity));
