import { createStore, applyMiddleware } from "redux";
import { compose } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers";

const initalState = {};

const middleware = [thunk];

/* Original source
const store = createStore(
  rootReducer,
  initalState,
  composeWithDevtools(applyMiddleware(...middleware))
);
*/

// troubleshooting source
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  initalState,
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? composeEnhancers(applyMiddleware(...middleware))
    : applyMiddleware(...middleware)
);

export default store;
