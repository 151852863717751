import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Spinner from "../layout/Spinner";
import { getCommunityById } from "../../actions/community";

function Community({
  match,
  getCommunityById,
  community: { community, loading },
}) {
  useEffect(() => {
    getCommunityById(match.params.id);
  }, [getCommunityById]);

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="profile">
            <div className="edit">
              <Link to={`/edit-community/${match.params.id}`}>Edit</Link>
            </div>
            <div className="inner">
              <p className="name">{community && community.name}</p>
              <p className="co">{community && community.company_id.name}</p>
            </div>
          </div>

          <div className="list profile-list">
            <div className="item">
              <div className="label">Latitude</div>
              <div className="data">{community && community.latitude}</div>
            </div>
            <div className="item">
              <div className="label">Longitude</div>
              <div className="data">{community && community.longitude}</div>
            </div>
            <div className="item">
              <div className="label">Address</div>
              <div className="data">{community && community.address}</div>
            </div>
            <div className="item">
              <div className="label">City</div>
              <div className="data">{community && community.city}</div>
            </div>
            <div className="item">
              <div className="label">State</div>
              <div className="data">{community && community.state}</div>
            </div>
            <div className="item">
              <div className="label">Zipcode</div>
              <div className="data">{community && community.zipcode}</div>
            </div>
            <p></p>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

Community.propTypes = {
  getCommunityById: PropTypes.func.isRequired,
  community: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  community: state.community,
});

export default connect(mapStateToProps, { getCommunityById })(Community);
