import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import user from "./user";
import company from "./company";
import community from "./community";
import lead from "./lead";
import filter from "./filter";

export default combineReducers({
  alert,
  auth,
  user,
  company,
  community,
  lead,
  filter,
});
