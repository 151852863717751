export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const GET_USERS = "GET_USERS";
export const GET_USER = "GET_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANIES = "GET_COMPANIES";
export const COMPANY_ERROR = "COMPANY_ERROR";
export const CLEAR_COMPANY = "CLEAR_COMPANY";

export const GET_COMMUNITY = "GET_COMMUNITY";
export const GET_COMMUNITIES = "GET_COMMUNITIES";
export const COMMUNITY_ERROR = "COMMUNITY_ERROR";
export const CLEAR_COMMUNITY = "CLEAR_COMMUNITY";

export const GET_LEADS = "GET_LEADS";
export const LEAD_ERROR = "LEAD_ERROR";
export const CLEAR_LEADS = "CLEAR_LEADS";

export const ADD_FILTER = "ADD_FILTER";
export const UPDATE_FILTER = "UPDATE_FILTER";
export const FILTER_ERROR = "FILTER_ERROR";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const CLEAR_FILTERS_LEADS = "CLEAR_FILTERS_LEADS";
export const REMOVE_FILTER = "REMOVE_FILTER";
