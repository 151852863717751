import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { getUsers, assignACommunity } from "../../actions/user";
import { getCommunities } from "../../actions/community";
import PropTypes from "prop-types";

const AssignCommunity = ({
  getCommunities,
  getUsers,
  assignACommunity,
  community: { communities },
  user: { users, loading },
  auth: { user },
  history,
}) => {
  useEffect(() => {
    getCommunities();
    getUsers();
  }, [getCommunities, getUsers]);

  useEffect(() => {
    let filtered = users;
    if (user.access_level > 1) {
      filtered = users.filter((u) => u.company_id._id === user.company_id._id);
    }

    let commFiltered = communities;
    if (user.access_level > 1) {
      commFiltered = communities.filter(
        (u) => u.company_id._id === user.company_id._id
      );
    }

    setData({
      usersFiltered: filtered,
      communitiesFiltered: commFiltered,
    });
  }, [user, users, communities]);

  const [data, setData] = useState({
    usersFiltered: [],
    communitiesFiltered: [],
  });

  const [formData, setFormData] = useState({
    user_id: "",
    community_id: "",
  });

  const { user_id, community_id } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();

    assignACommunity({ user_id, community_id }, history);
  };

  // company options, admin shows all, other autosets to same co
  const communitySelect = () => {
    return (
      <select
        placeholder="Community"
        name="community_id"
        value={community_id}
        onChange={(e) => onChange(e)}
      >
        <option>Community</option>
        {data.communitiesFiltered.length > 0
          ? data.communitiesFiltered.map((community) => (
              <option value={community._id} key={community._id}>
                {community.company_id.name}: {community.name}
              </option>
            ))
          : null}
      </select>
    );
  };

  // user options, admin shows all, other autosets to same co
  const userSelect = () => {
    return (
      <select
        placeholder="User"
        name="user_id"
        value={user_id}
        onChange={(e) => onChange(e)}
      >
        <option>User</option>
        {data.usersFiltered.length > 0
          ? data.usersFiltered.map((u) => (
              <option value={u._id} key={u._id}>
                {u.name}
              </option>
            ))
          : null}
      </select>
    );
  };

  return (
    <Fragment>
      <h2>Assign Community</h2>
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">{communitySelect()}</div>
        <div className="form-group">{userSelect()}</div>
        <button type="submit" className="btn">
          Assign
        </button>
      </form>
    </Fragment>
  );
};

AssignCommunity.propTypes = {
  getCommunities: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  assignACommunity: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  community: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  community: state.community,
  user: state.user,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getCommunities,
  getUsers,
  assignACommunity,
})(withRouter(AssignCommunity));
