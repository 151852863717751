import React, { useState } from "react";
import PropTypes from "prop-types";

function Dropdown({ onChange, options, title }) {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelected] = useState(undefined);

  const displayOptions = () => {
    if (options instanceof Array) {
      return options.map(function (obj) {
        return (
          <li
            key={obj._id}
            onClick={() => {
              selectOption(obj._id);
              setSelected(obj.name);
            }}
            className={selectedOption === obj.name ? "selected" : ""}
          >
            <a href="#">{obj.name}</a>
          </li>
        );
      });
    }
  };

  const selectOption = (id) => {
    onChange(id);
    setOpen(false);
  };

  return (
    <div
      className={open === true ? "dropdown active" : "dropdown"}
      onClick={() => setOpen(!open)}
    >
      <a href="#" data-toggle="dropdown">
        {selectedOption ? selectedOption : title}
        <i className="icon-arrow"></i>
      </a>
      <ul className={open === true ? "dropdown-menu active" : "dropdown-menu"}>
        {displayOptions()}
      </ul>
    </div>
  );
}

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array,
  title: PropTypes.string.isRequired,
};

export default Dropdown;
