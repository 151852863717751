import axios from "axios";
import { setAlert } from "./alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  GET_USERS,
  GET_USER,
  AUTH_ERROR,
} from "./types";

// get all users
export const getUsers = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/users");

    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Get user by ID
export const getUserById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/users/single/${id}`);

    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = (
  { name, email, company_id, access_level, password, avatar },
  history
) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    name,
    email,
    company_id,
    access_level,
    password,
    avatar,
  });

  try {
    const res = await axios.post("/api/users", body, config);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });

    dispatch(setAlert("User added"));
    history.push(`/user/${res.data._id}`);
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: REGISTER_FAIL,
    });
  }
};

// Update User
export const updateUser = (
  { id, name, company_id, access_level, avatar },
  history
) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    id,
    name,
    company_id,
    access_level,
    avatar,
  });

  try {
    const res = await axios.post("/api/users/update/", body, config);

    dispatch(setAlert("User updated"));
    history.push(`/user/${res.data._id}`);
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};

// Assign A Community
export const assignACommunity = ({ user_id, community_id }, history) => async (
  dispatch
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    user_id,
    community_id,
  });

  try {
    const res = await axios.post("/api/users/assign/", body, config);

    dispatch(setAlert("Community assigned"));
    history.push(`/user/${user_id}`);
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
  }
};
