import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Spinner from "../layout/Spinner";
import { getCommunities } from "../../actions/community";
import { getCompanies } from "../../actions/company";

import CommunityItem from "./CommunityItem";

function Communities({
  getCommunities,
  getCompanies,
  community: { communities, loading },
  company: { companies },
  auth: { user },
}) {
  useEffect(() => {
    getCompanies();
    getCommunities();
  }, []);

  const [communitiesFiltered, setCommunitiesFiltered] = useState([]);
  const [company_id, setCompanyId] = useState("");

  useEffect(() => {
    let filtered = communities;
    if (user && user.access_level == 2) {
      filtered = communities.filter(
        (community) => community.company_id._id === user.company_id._id
      );
    } else if (user && user.access_level > 2) {
      filtered = communities.filter((community) =>
        user.communities.includes(community._id)
      );
    }

    setCommunitiesFiltered(filtered);
  }, [user, communities]);

  const selectCompany = (e) => {
    setCompanyId(e.target.value);

    let filtered = communities;
    filtered = communities.filter(
      (community) => community.company_id._id === e.target.value
    );

    setCommunitiesFiltered(filtered);
  };

  const companySelect = () => {
    if (user && user.access_level <= 1) {
      return (
        <select
          placeholder="Choose Client"
          name="company_id"
          value={company_id}
          onChange={(e) => selectCompany(e)}
        >
          <option>Company</option>
          {companies && companies.length > 0
            ? companies.map((company) => (
                <option value={company._id} key={company._id}>
                  {company.name}
                </option>
              ))
            : null}
        </select>
      );
    }
  };

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h2>All Communities</h2>
          <div className="communities list">
            {companySelect()}
            {communitiesFiltered.length > 0 ? (
              communitiesFiltered.map((community) => (
                <CommunityItem key={community._id} community={community} />
              ))
            ) : (
              <p>No communities found...</p>
            )}
          </div>
          {user && user.access_level <= 1 && (
            <div className="btn-cont">
              <Link to="/create-community">Add Community</Link>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

Communities.propTypes = {
  getCommunities: PropTypes.func.isRequired,
  getCompanies: PropTypes.func.isRequired,
  community: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  company: state.company,
  community: state.community,
  auth: state.auth,
});

export default connect(mapStateToProps, { getCommunities, getCompanies })(
  Communities
);
