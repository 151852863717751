import React from "react";
import PropTypes from "prop-types";

const Progress = ({ percentage }) => {
  return (
    <div className="progress-bar">
      <div className="bar">
        <div className="progress" style={{ width: percentage + "%" }}></div>
      </div>
      <span>{percentage}%</span>
    </div>
  );
};

Progress.propTypes = {
  percentage: PropTypes.number.isRequired,
};

export default Progress;
