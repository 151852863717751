import React, { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  createCompany,
  deleteCompany,
  getCompanyById,
} from "../../actions/company";

import FileUpload from "../layout/FileUpload";

const EditCompany = ({
  match,
  createCompany,
  deleteCompany,
  getCompanyById,
  company: { company, loading },
  history,
}) => {
  useEffect(() => {
    getCompanyById(match.params.id);
  }, []);

  useEffect(() => {
    setFormData({
      id: loading || !company ? null : company._id,
      name: loading || !company ? "" : company.name,
      logo: loading || !company ? "" : company.logo,
    });
  }, [company]);

  const [formData, setFormData] = useState({
    name: "",
    id: null,
    logo: "",
  });

  const { name, id, logo } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    createCompany(formData, history, true);
  };

  const onImageUpload = (url) => {
    setFormData({ ...formData, logo: url });
  };

  const deleteTheCompany = () => {
    deleteCompany(id, history);
  };

  return (
    <Fragment>
      <h2>Edit Company</h2>
      <FileUpload currentFile={logo} onComplete={onImageUpload} />
      <form onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Company Name"
            name="name"
            value={name}
            onChange={(e) => onChange(e)}
            required
          />
        </div>
        <button type="submit" className="btn">
          Update
        </button>
      </form>
      <button
        onClick={() => {
          deleteTheCompany();
        }}
        className="btn red"
      >
        Delete
      </button>
    </Fragment>
  );
};

EditCompany.propTypes = {
  createCompany: PropTypes.func.isRequired,
  deleteCompany: PropTypes.func.isRequired,
  getCompanyById: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  company: state.company,
});

export default connect(mapStateToProps, {
  createCompany,
  deleteCompany,
  getCompanyById,
})(withRouter(EditCompany));
