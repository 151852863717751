import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { uid } from "react-uid";

// Components
import DatePicker from "react-date-picker";
import Checkbox from "../layout/Checkbox";

// Actions
import { addFilter, updateFilter } from "../../actions/filter";

function Filter({
  getLeads,
  addFilter,
  updateFilter,
  removeFilter,
  addNewFilter,
  id,
  color,
  initStartDate,
  initEndDate,
  gradeOptions,
  sourceOptions,
  salesStatusOptions,
  globalShowAgreements,
  primary,
  lead: { leads, loading },
}) {
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [sources, setSources] = useState([]);
  const [grades, setGrades] = useState([]);
  const [showAgreements, setShowAgreements] = useState(false);
  const [showSources, setShowSources] = useState(false);
  const [filterSources, setFilterSources] = useState("");
  const [agreementCount, setAgreementCount] = useState(0);
  const [trafficCount, setTrafficCount] = useState(0);

  useEffect(() => {
    addFilter({ id: id, leads: leads, color: color });
    if (globalShowAgreements) {
      console.log(globalShowAgreements);
      setShowAgreements(globalShowAgreements);
    }
  }, []);

  useEffect(() => {
    setShowAgreements(globalShowAgreements);
  }, [globalShowAgreements]);

  useEffect(() => {
    runFilter();
  }, [startDate, endDate, sources, grades, showAgreements]);

  useEffect(() => {
    updateFilter({
      id: id,
      leads: filteredLeads,
      color: color,
      showAgreements: showAgreements,
    });
  }, [filteredLeads, showAgreements]);

  // new lead data - set min max dates
  useEffect(() => {
    setSources(sourceOptions);
    setGrades(gradeOptions);
  }, [sourceOptions, gradeOptions]);

  // set date if init set
  useEffect(() => {
    setStartDate(initStartDate);
    setEndDate(initEndDate);
  }, [initStartDate, initEndDate]);

  // date range
  const onChangeStart = (date) => setStartDate(date);
  const onChangeEnd = (date) => setEndDate(date);

  // sources
  const displaySources = () => {
    if (sourceOptions instanceof Array) {
      let _sourceOptions = sourceOptions;

      if (filterSources) {
        _sourceOptions = _sourceOptions.filter((object) =>
          object.toLowerCase().includes(filterSources.toLowerCase())
        );
      }

      return _sourceOptions.map(function (object, i) {
        return (
          <Checkbox
            obj={object}
            key={uid(object, i) + id}
            id={uid(object, i) + id}
            prefix="source"
            onCheckbox={sourceChange}
            defaultValue={true}
          />
        );
      });
    }
  };

  const filterTheSources = (e) => {
    setFilterSources(e.target.value);
  };

  const sourceChange = (event) => {
    if (event.currentTarget.checked) {
      var _sources = [...sources];
      _sources.push(event.currentTarget.value);

      console.log(_sources);
      setSources(_sources);
    } else {
      var _sources = [...sources].filter(
        (item) => item !== event.currentTarget.value
      );
      console.log(_sources);
      setSources(_sources);
    }
  };

  // grades
  const displayGrades = () => {
    if (gradeOptions instanceof Array) {
      return gradeOptions.map(function (object, i) {
        return (
          <Checkbox
            obj={object}
            key={uid(object, i) + id}
            id={uid(object, i) + id}
            prefix="grade"
            onCheckbox={gradeChange}
          />
        );
      });
    }
  };

  const gradeChange = (event) => {
    if (event.currentTarget.checked) {
      var _grades = [...grades];
      _grades.push(event.currentTarget.value);

      console.log(_grades);
      setSources(_grades);
    } else {
      var _grades = [...grades].filter(
        (item) => item !== event.currentTarget.value
      );
      console.log(_grades);
      setSources(_grades);
    }
  };

  // show sales status
  const statusChanged = (event) => {
    console.log(event.currentTarget.checked);
    setShowAgreements(event.currentTarget.checked);
  };

  // run filter
  const runFilter = () => {
    const filteredPoints = [];
    let _agreementCount = 0;
    let _trafficCount = 0;

    if (leads) {
      leads.map((point) => {
        let display = true;

        // check within date range
        let date = new Date(point.date);
        if (date < startDate || date > endDate) {
          display = false;
        }

        // show correct sources
        if (!sources.includes(point.source.trim())) {
          display = false;
        }

        // show only agreements
        if (showAgreements === "agreements" && point.salesStatus === "") {
          display = false;
        } else if (
          display === true &&
          showAgreements === "agreements" &&
          point.salesStatus !== ""
        ) {
          _agreementCount++;
        }

        if (showAgreements === "traffic" && point.salesStatus !== "") {
          display = false;
        } else if (
          display === true &&
          showAgreements === "traffic" &&
          point.salesStatus === ""
        ) {
          _trafficCount++;
        }

        if (showAgreements === "both" && display === true) {
          if (point.salesStatus === "") {
            _trafficCount++;
          } else {
            _agreementCount++;
          }
        }

        if (showAgreements === "none") {
          display = false;
        }

        if (display) {
          filteredPoints.push(point);
        }
      });

      setFilteredLeads(filteredPoints);
      setAgreementCount(_agreementCount);
      setTrafficCount(_trafficCount);
    }
  };

  const showDate = (date) => {
    let _m = date.getMonth() + 1;
    let _d = date.getDate();
    let _y = date.getFullYear();

    return _m + "/" + _d + "/" + _y;
  };

  const fullUI = () => {
    return (
      <div className="full">
        <div className={"filter " + color}>
          <div className="dates">
            <DatePicker onChange={onChangeStart} value={startDate} />
            <DatePicker onChange={onChangeEnd} value={endDate} />
          </div>
          <img src={`images/icons/pin-${color}.png`} className="icon" />

          <div className={showSources ? "sources active" : "sources"}>
            <div
              className="title"
              onClick={() => {
                setShowSources(!showSources);
                setFilterSources("");
              }}
            >
              Sources:
            </div>
            <div className="list">
              <div className="filter">
                <input
                  type="text"
                  placeholder="Filter"
                  name="source-filter"
                  value={filterSources}
                  onChange={(e) => filterTheSources(e)}
                />
                <div
                  className="clear"
                  onClick={() => {
                    setFilterSources("");
                  }}
                >
                  &times;
                </div>
              </div>
              {displaySources()}
            </div>
          </div>

          <Checkbox
            obj="Show Only Agreements"
            id={uid(id)}
            prefix="agreements"
            onCheckbox={statusChanged}
            additionalClassName="house"
            defaultCheckedFalse={true}
          />

          <div className="count">Count: {filteredLeads.length}</div>

          <div className="add-remove">
            <div className="add">
              <span>+</span>
              <div className="subnav">
                <div className="desc">Compare to:</div>
                <div
                  onClick={() => {
                    let _initStartDate = new Date(startDate);
                    _initStartDate.setMonth(_initStartDate.getMonth() - 3);

                    let _initEndDate = new Date(endDate);
                    _initEndDate.setMonth(_initEndDate.getMonth() - 3);

                    addNewFilter(_initStartDate, _initEndDate);
                  }}
                >
                  Previous Period
                </div>
                <div
                  onClick={() => {
                    let _initStartDate = new Date(startDate);
                    _initStartDate.setMonth(_initStartDate.getMonth() - 1);

                    let _initEndDate = new Date(endDate);
                    _initEndDate.setMonth(_initEndDate.getMonth() - 1);

                    addNewFilter(_initStartDate, _initEndDate);
                  }}
                >
                  Previous Month
                </div>
                <div
                  onClick={() => {
                    let _initStartDate = new Date(startDate);
                    _initStartDate.setMonth(_initStartDate.getMonth() - 12);

                    let _initEndDate = new Date(endDate);
                    _initEndDate.setMonth(_initEndDate.getMonth() - 12);

                    addNewFilter(_initStartDate, _initEndDate);
                  }}
                >
                  Previous Year
                </div>
                <div onClick={() => addNewFilter()}>Custom</div>
              </div>
            </div>
            <div className="delete" onClick={() => removeFilter(id)}>
              <span>-</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const legend = () => {
    return (
      <div className="legend-row">
        {primary > 1 && (
          <div className="date">
            <p>
              <span>{showDate(startDate)}</span> -{" "}
              <span>{showDate(endDate)}</span>
            </p>
          </div>
        )}
        <div className="count">
          <img src={`images/icons/svg/pin-${color}.svg`} className="icon" />
          <span>{trafficCount}</span>
        </div>
        <div className="count">
          <img src={`images/icons/svg/agreement-${color}.svg`} className="icon" />
          <span>{agreementCount}</span>
        </div>
      </div>
    );
  };

  return legend();
}

Filter.propTypes = {
  addFilter: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  addNewFilter: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  initStartDate: PropTypes.instanceOf(Date),
  initEndDate: PropTypes.instanceOf(Date),
  gradeOptions: PropTypes.array,
  sourceOptions: PropTypes.array,
  salesStatusOptions: PropTypes.array,
  globalShowAgreements: PropTypes.string,
  primary: PropTypes.number,
};

const mapStateToProps = (state) => ({
  lead: state.lead,
});

export default connect(mapStateToProps, { addFilter, updateFilter })(Filter);
