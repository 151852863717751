import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Spinner from "../layout/Spinner";
import { getUsers } from "../../actions/user";
import UserItem from "./UserItem";

function Users({ getUsers, user: { users, loading }, auth: { user } }) {
  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    let filtered = users;
    if (user.access_level > 1) {
      filtered = users.filter((u) => u.company_id._id === user.company_id._id);
    }

    setData({
      usersFiltered: filtered,
    });
  }, [user, users]);

  const [data, setData] = useState({
    usersFiltered: [],
  });

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h2>All Users</h2>
          <div className="users list">
            {data.usersFiltered.length > 0 ? (
              data.usersFiltered.map((user) => (
                <UserItem key={user._id} user={user} />
              ))
            ) : (
              <p>No users found...</p>
            )}
          </div>
          <div className="btn-cont">
            <Link to="/register-user">Add User</Link>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

Users.propTypes = {
  getUsers: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  auth: state.auth,
});

export default connect(mapStateToProps, { getUsers })(Users);
