import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const UserItem = ({ user: { _id, name } }) => {
  return (
    <div className="item">
      <Link to={`/user/${_id}`}>{name}</Link>
      <div className="links">
        <Link to={`/edit-user/${_id}`}>Edit</Link>
      </div>
    </div>
  );
};

UserItem.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserItem;
