import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_COMMUNITY,
  GET_COMMUNITIES,
  COMMUNITY_ERROR,
  CLEAR_COMMUNITY,
} from "./types";

// Get community by ID
export const getCommunityById = (id) => async (dispatch) => {
  dispatch({ type: CLEAR_COMMUNITY });

  try {
    const res = await axios.get(`/api/community/single/${id}`);

    dispatch({
      type: GET_COMMUNITY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: COMMUNITY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// get all communities
export const getCommunities = () => async (dispatch) => {
  dispatch({ type: CLEAR_COMMUNITY });

  try {
    const res = await axios.get("/api/community");

    dispatch({
      type: GET_COMMUNITIES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: COMMUNITY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Ceate or update community
export const createCommunity = (formData, history, edit = false) => async (
  dispatch
) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axios.post("/api/community", formData, config);

    dispatch({
      type: GET_COMMUNITY,
      payload: res.data,
    });

    dispatch(setAlert(edit ? "Community updated" : "Community added"));

    history.push(`/community/${res.data._id}`);
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: COMMUNITY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};

// Delete community by ID
export const deleteCommunity = (id, history) => async (dispatch) => {
  dispatch({ type: CLEAR_COMMUNITY });

  try {
    const res = await axios.delete(`/api/community/${id}`);

    dispatch(setAlert("Community deleted"));

    history.push("/communities");
  } catch (err) {
    dispatch({
      type: COMMUNITY_ERROR,
      payload: {
        msg: err.response.statusText,
        status: err.response.status,
      },
    });
  }
};
