import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function TabSection({ content }) {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <div className="tab-section">
      <div className="tab-nav">
        {content.length > 0 ? (
          content.map((tab, index) => (
            <div
              className={currentTab === index ? "tab-link active" : "tab-link"}
              key={index}
              onClick={() => {
                setCurrentTab(index);
              }}
            >
              {tab.nav}
            </div>
          ))
        ) : (
          <p>No tabs found...</p>
        )}
      </div>
      <div className="tab-cont">
        {content.length > 0 ? (
          content.map((tab, index) => (
            <div
              className={currentTab === index ? "tab-item active" : "tab-item"}
              key={index}
            >
              {tab.content}
            </div>
          ))
        ) : (
          <p>No tabs found...</p>
        )}
      </div>
    </div>
  );
}

TabSection.propTypes = {
  content: PropTypes.array.isRequired,
};

export default TabSection;
