import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { uid, useUID } from "react-uid";
import { connect } from "react-redux";

// Component
import TopBar from "../layout/TopBar";
import Alert from "../layout/Alert";
import Spinner from "../layout/Spinner";
import Dropdown from "../layout/Dropdown";
import Map from "./Map";
import CompareController from "./CompareController";

// Actions
import { getCommunities } from "../../actions/community";

function MapView({ getCommunities, user, communities }) {
  // State
  const [communitiesFiltered, setCommunitiesFiltered] = useState([]);
  const [community, setCommunity] = useState(null);

  useEffect(() => {
    getCommunities();
  }, []);

  // filter community options
  useEffect(() => {
    let filtered = communities;
    if ((user && user.access_level == 2) || (user && user.access_level == 3)) {
      filtered = communities.filter(
        (community) => community.company_id._id === user.company_id._id
      );
    } else if (user && user.access_level > 3) {
      filtered = user.communities;
    }

    setCommunitiesFiltered(filtered);
  }, [user, communities]);

  return (
    <div className="map-view">
      <header>
        <TopBar />
        <div className="comm-nav">
          <p>Choose a Community</p>
          <Dropdown
            title="Community"
            options={communitiesFiltered}
            onChange={(id) => setCommunity(id)}
          />
        </div>
        <div className="map-nav">
          <Alert />
          <CompareController community={community} />
        </div>
      </header>
      <div className="main">
        <Map initLat={41.850033} initLng={-87.6500523} initZoom={4} />
      </div>
    </div>
  );
}

MapView.propTypes = {
  auth: PropTypes.object.isRequired,
  getCommunities: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.auth.user,
  communities: state.community.communities,
});

export default connect(mapStateToProps, {
  getCommunities,
})(MapView);
