import {
  ADD_FILTER,
  UPDATE_FILTER,
  REMOVE_FILTER,
  FILTER_ERROR,
  CLEAR_FILTERS,
  CLEAR_FILTERS_LEADS,
} from "../actions/types";

const initalState = {
  filters: [],
  filterRefresh: null,
  loading: true,
  error: {},
};

export default function (state = initalState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_FILTER:
      return {
        ...state,
        filters: [...state.filters, payload],
        filterRefresh: Date.now(),
        loading: false,
      };
    case UPDATE_FILTER:
      const index = state["filters"].findIndex((i) => i.id === payload.id);
      const updatedFilters = state["filters"];
      updatedFilters[index] = payload;

      return {
        ...state,
        filters: updatedFilters,
        filterRefresh: Date.now(),
        loading: false,
      };
    case REMOVE_FILTER:
      let _filters = state["filters"].filter(
        (item, itemIndex) => item.id !== payload
      );

      return {
        ...state,
        filters: _filters,
        filterRefresh: Date.now(),
        loading: false,
      };
    case FILTER_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_FILTERS:
      return {
        ...state,
        filters: [],
        loading: false,
      };
    case CLEAR_FILTERS_LEADS:
      for (var i in state["filters"]) {
        state["filters"][i].leads = [];
      }

      return {
        ...state,
        filterRefresh: Date.now(),
        loading: false,
      };
    default:
      return state;
  }
}
