import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function Checkbox({
  id,
  prefix,
  additionalClassName,
  obj,
  onCheckbox,
  defaultCheckedFalse,
}) {
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    if (defaultCheckedFalse === true) {
      setChecked(false);
    }
  }, [defaultCheckedFalse]);

  return (
    <div className={additionalClassName ? `${additionalClassName} cb` : `cb`}>
      <input
        type="checkbox"
        name="source"
        id={prefix + "-cb-" + id}
        value={obj}
        onChange={(e) => {
          setChecked(!checked);
          onCheckbox(e);
        }}
        checked={checked}
      />
      <label htmlFor={prefix + "-cb-" + id}>{obj}</label>
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  additionalClassName: PropTypes.string,
  obj: PropTypes.string.isRequired,
  onCheckbox: PropTypes.func.isRequired,
  defaultCheckedFalse: PropTypes.bool,
};

export default Checkbox;
